.wrapper {
  width: 100vw;
  max-width: 1200px;
  position: relative;
  }
  #left {
    background-color: #6a6a6a;
    border-radius: 60%;
    color: white;
    cursor: pointer;
    padding: 10px;
  }
  
  #left:hover {
    background-color: #151414;
  }

  
  
  
  .wrapper i {
  top: 50%;
  height: 46px;
  width: 46px;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  line-height: 46px;
  background-color: #fff;
  color: black;
  border-radius: 50%;
  transform: translateY(-50%);
  }
  
  .wrapper i:first-child {
  left: -23px;
  }
  
  .wrapper i:last-child {
  right: -23px;
  }
  
  .wrapper .carousel_2 {
  font-size: 0px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  scroll-behavior: smooth;
  }
  
  .carousel_2.dragging {
  cursor: grab;
  scroll-behavior: auto;
  }
  
  .carousel_2.dragging img {
  pointer-events: none;
  }
  
  .carousel_2 img {
  margin-left: 14px;
  height: 300px;
  object-fit: cover;
  width: calc(100% / 3);
  }
  
  .carousel_2 img:first-child {
  margin-left: 0px;
  }
  
  .container-gallery {
  height: 50vh;
  }
  
  #gallery {
  padding: 50px 0;
  }
  .wrapper {
  display: flex;
  align-items: center;
  }
  
  .carousel_2 {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  }
  
  .carousel_2 img {
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  }
  
  .wrapper i {
  font-size: 30px;
  color: #555;
  margin: 0 10px;
  cursor: pointer;
  }
  
  /* Modal styling */
  #myModal {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  }
  .modal{
    display: none;
  position: fixed;

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
    
  }
  
  .modal .modal-content {
  margin-top: 90px;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  display: block;
  height: auto;
  width: 90vw;
  max-width: 700px;
  max-height: 80%;
  overflow: auto;
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  }
  
  .modal .close {
  color: #c91616;
  float: right;
  font-size: 40px;
  font-weight: 900;
  margin-right: 90px;
  margin-bottom:5rem ;
  }
   .close {
  
   margin-top: 20px;
    }
  
  .modal .close:hover,
  .modal .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
  }
  
  #left,
  #right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 3rem;
  cursor: pointer;
  }
  
  #left {
  left: 1rem;
  }
  
  #right {
  right: 1rem;
  }
  .modal-close-btn {
    background-color: #ccc;
    border-radius: 50%;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
  }
  
  .modal-close-btn:hover {
    background-color: #aaa;
  }
  
  @media screen and (max-width: 767px) {
  .wrapper i {
  font-size: 1.6rem;
  height: 60px;
  width: 60px;
  line-height: 60px;
  }
  
  .carousel_2 img {
  height: 200px;
  }
  
  .modal-content {
  max-height: 70%;
  }
  }
  @media screen and (max-width: 900px) {
    .carousel_2 img {
      width: calc(100% / 2);
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .carousel_2 {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
  }
  
  .carousel_2 img {
    flex: 0 0 calc(100% / 3);
    height: 300px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  @media screen and (max-width: 900px) {
    .carousel_2 img {
      flex: 0 0 calc(100% / 2);
    }
  }
  
  @media screen and (max-width: 767px) {
    .wrapper {
      flex-direction: column;
    }
    
    .carousel_2 img {
      height: 200px;
    }
    
  }
  