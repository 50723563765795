.carousel-item {
  height: 100vh;
  min-height: 300px;
  width: 100%;
}

.carousel-caption {
  bottom: 220px;
  z-index: 2;
}

.carousel-caption h3 {
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 25px;
  margin-bottom: 20px;
  color: #fff;
}

.carousel-caption p {
  width: 60%;
  margin: auto;
  font-size: 18px;
  line-height: 1.9;
}

.carousel-inner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(35, 33, 33, 0.7);
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .carousel-caption {
      bottom: 350px;
  }
  .carousel-item > #img {
    
    min-width: 80%;
    height: 100%;
}
  .carousel-caption p {
      width: 100%;
  }
  .carousel-inner::before {
      background: rgba(0, 0, 0, 0.9);
  }
  
  .carousel-item > img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 60%;
      height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .carousel-caption {
      bottom: 250px;
  }
  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 60%;
    height: 100%;
}
  .carousel-caption h3 {
      font-size: 30px;
  }
  .carousel-caption p {
      font-size: 16px;
  }
  .carousel-inner::before {
      background: rgba(0, 0, 0, 0.8);
  }
}

@media only screen and (max-width: 575px) {
  .carousel-caption {
      bottom: 150px;
  }
  .carousel-caption h3 {
      font-size: 25px;
  }
  .carousel-caption p {
      font-size: 14px;
      line-height: 1.6;
  }
  .carousel-inner::before {
      background: rgba(46, 38, 38, 0.7);
  }
}
