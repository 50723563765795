.navbar-nav a {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .navbar-light .navbar-brand {
    color: black;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
  }
  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover {
    color: #000;
  }
  .navbar-light .navbar-brand .navbar-link {
    border-top: 4px solid #000000;
    border-bottom: 4px solid #000000;
    padding: 6px 0px;
    
  }

  @media only screen and (max-width: 991px) {
    .navbar-nav a{
      position: sticky;
      top:0;
      z-index: 100;
    }
  }